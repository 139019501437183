import React from "react";

const Header = () => {
  return (
    <article>
      <header>
        {/* <img src="../logo.png" alt="company logo" className="logo" /> */}
      </header>
    </article>
  );
};

export default Header;
