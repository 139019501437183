import React, { useEffect, useState, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useRenewContext } from "../context/renew_context";
import "react-toastify/dist/ReactToastify.css";
import queryString from "query-string";
import Header from "../components/Header";
import Recaptcha from "react-google-recaptcha";
import lauditor_logo from "../resources/images/lauditor.png";
import regswatch_logo from "../resources/images/regswatch.png";
import vitagist_logo from "../resources/images/vitagist.png";
import content_logo from "../resources/images/content.png";
import dgcounsel_logo from "../resources/images/dgcounsel.png";

import {
  PROD_LAUDITOR,
  PROD_REGSWATCH,
  PROD_COFFER,
  PROD_CONTENT,
  PROD_DGCOUNSEL,
  PROD_VITAGIST,
} from "../actions";

const Renew = () => {
  const btnSubmitRef = useRef(null); //used for Button Toggling CSS
  const captchaRef = useRef(null);
  const parsedqs = queryString.parse(window.location.search); //Parse Query String
  let { prod, users, useremail } = parsedqs || {
    prod: PROD_LAUDITOR,
    users: 1,
    useremail: "",
  };
  if (process.env.REACT_APP_PRODUCT) {
    prod = process.env.REACT_APP_PRODUCT;
  } else {
    prod = prod || PROD_LAUDITOR;
  }
  try {
    users = users || 1;
    users = parseInt(users);
  } catch (error) {
    users = 1;
  }
  const {
    fname,
    updateQty,
    updateEmail,
    redirectUrl,
    message,
    errorStatus,
    updateInputValue,
    updateCaptchaToken,
    findProfUser,
    updateRedirectUrl,
    setProduct,
  } = useRenewContext();
  let { qty, product, email } = useRenewContext();
  qty = users > qty ? users : qty;
  qty = parseInt(qty);
  //console.log(qty);
  product = prod || product;
  if (process.env.REACT_APP_PRODUCT) {
    setProduct(process.env.REACT_APP_PRODUCT);
  } else {
    setProduct(product);
  }

  email = useremail || email;
  console.log(qty);
  //Update the Qty passed as Querystring
  useEffect(() => {
    updateQty(qty);
  }, [qty, updateQty]);

  useEffect(() => {
    updateEmail(useremail);
  }, [useremail, updateEmail]);

  useEffect(() => {
    if (redirectUrl !== "") {
      window.location.href = redirectUrl;
    }
  }, [redirectUrl]);

  useEffect(() => {
    if (message !== "") {
      notify(!errorStatus, message);
    }
  }, [errorStatus, message]);

  useEffect(() => {
    updateRedirectUrl("");
  }, [redirectUrl]);

  const [disabled, setDisabled] = useState(true);
  const agreeClick = () => {
    setDisabled(!disabled);
    addRemoveClass(!disabled);
  };
  const addRemoveClass = (status) => {
    if (status) {
      btnSubmitRef.current.classList.remove("active");
      btnSubmitRef.current.classList.add("inactive");
    } else {
      btnSubmitRef.current.classList.remove("inactive");
      btnSubmitRef.current.classList.add("active");
    }
  };

  const notify = (error, msg) => {
    if (error) toast.error(msg);
    else toast.success(msg);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const token = captchaRef.current.getValue();
    console.log(token);
    updateCaptchaToken(token);
    captchaRef.current.reset();
    findProfUser(token);
  };

  const limitInput = (e) => {
    if (e.target.value.length >= 3) {
      e.target.value = e.target.value.slice(0, 2);
    }
  };

  const getLogo = (product) => {
    switch (product) {
      case PROD_LAUDITOR:
        return lauditor_logo;
      case PROD_REGSWATCH:
        return regswatch_logo;
      case PROD_VITAGIST:
        return vitagist_logo;
      case PROD_CONTENT:
        return content_logo;
      case PROD_DGCOUNSEL:
        return dgcounsel_logo;
      default:
        return lauditor_logo;
    }
  };
  return (
    <>
      <Header />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        bodyClassName="toastBody"
      />

      <div className="section section-center">
        {/* <div className="title">Renew Subscription</div> */}

        <form
          className="mod-form"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <img src={getLogo(product)} alt="company logo" className="logo" />
          <div className="form-group">
            <p className="sub-title">
              Submit the form to renew your subscription.
            </p>
          </div>
          <div className="form-group">
            <label className="mod-label" htmlFor="email">
              Email
            </label>
            <input
              className="mod-input"
              type="email"
              id="email"
              disabled={true}
              name="email"
              value={email}
              required
            />
            <input type="hidden" id="product" name="product" value={product} />
          </div>
          {prod !== PROD_REGSWATCH && prod !== PROD_VITAGIST && (
            <div className="form-group">
              <label className="mod-label" htmlFor="users">
                Number of Users
              </label>
              <input
                className="mod-input"
                type="number"
                maxLength={2}
                id="qty"
                name="qty"
                value={qty}
                onChange={updateInputValue}
                onInput={limitInput}
                required
              />
            </div>
          )}
          <div className="form-group">
            <label className="mod-label" htmlFor="terms">
              <input
                className="mod-checkbox"
                id="terms"
                name="terms"
                type="checkbox"
                onClick={agreeClick}
              />
              I accept the Terms of Service and Privacy Policy.
            </label>
          </div>
          <div className="form-group">
            <p className="help-text">
              By signing up you agreeing to our
              <a href="https://digicoffer.com/digicoffer_terms.pdf">Terms</a> &
              &nbsp;
              <a href="https://digicoffer.com/privacy.html">Privacy Policy</a>
            </p>
          </div>
          <div className="form-group">
            <div className="cap-center">
              <Recaptcha
                sitekey={process.env.REACT_APP_SITE_KEY}
                ref={captchaRef}
              />
            </div>
          </div>
          <div className="button-container">
            <button
              id="btnSubmit"
              name="btnSubmit"
              ref={btnSubmitRef}
              disabled={disabled}
              className="btn-small hot inactive"
              onClick={handleSubmit}
            >
              Renew
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default Renew;
