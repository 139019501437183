import React, { useEffect, useState, useRef } from "react";
import { useRegisterContext } from "../context/register_context";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import queryString from "query-string";
import Header from "../components/Header";
import Recaptcha from "react-google-recaptcha";
import lauditor_logo from "../resources/images/lauditor.png";
import regswatch_logo from "../resources/images/regswatch.png";
import vitagist_logo from "../resources/images/vitagist.png";
import content_logo from "../resources/images/content.png";
import dgcounsel_logo from "../resources/images/dgcounsel.png";

import {
  PROD_LAUDITOR,
  PROD_REGSWATCH,
  PROD_COFFER,
  PROD_CONTENT,
  PROD_DGCOUNSEL,
  PROD_VITAGIST,
} from "../actions";

const Register = () => {
  const btnSubmitRef = useRef(null); //used for Button Toggling CSS
  const captchaRef = useRef(null);
  const parsedqs = queryString.parse(window.location.search); //Parse Query String

  //We need to make these values come from DB post implementation of RegsWatch Self-Service
  let { prod, users, countrycode, consumeremail } = parsedqs || {
    prod: PROD_LAUDITOR,
    users: 1,
    countrycode: "",
    consumeremail: "",
  };
  if (process.env.REACT_APP_PRODUCT) {
    prod = process.env.REACT_APP_PRODUCT;
  } else {
    prod = prod || PROD_LAUDITOR;
  }

  try {
    users = users || 1;
    users = parseInt(users);
  } catch (error) {
    users = 1;
  }
  //const testObj = useRegisterContext();
  const [disabled, setDisabled] = useState(true);

  const [firmclass, setFirmclass] = useState("mod-input");
  const [emailclass, setEmailclass] = useState("mod-input");
  const [phoneclass, setPhoneclass] = useState("mod-input");
  const [cpersonclass, setCpersonclass] = useState("mod-input");
  const [countryclass, setCountryclass] = useState("mod-select");
  const [minvalue, setMinvalue] = useState(countrycode === "IN" ? 3 : 1);
  const [usersclass, setUsersclass] = useState("mod-input");
  const [arrqty, setArrqty] = useState(
    countrycode === "IN"
      ? [3, 4, 5, 6, 7, 8, 9, 10]
      : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
  );

  const [isReadOnly, setIsReadOnly] = useState(false);

  const [firmmsg, setFirmmsg] = useState("");
  const [emailmsg, setEmailmsg] = useState("");
  const [phonemsg, setPhonemsg] = useState("");
  const [countrymsg, setCountrymsg] = useState("");
  const [cpersonmsg, setCpersonmsg] = useState("");
  const [usersmsg, setUsersmsg] = useState("");
  const [btnTitle, setBtnTitle] = useState("Register");

  const agreeClick = (e) => {
    const isValid = isValidForm();
    // console.log(isValid);
    if (isValid && e.target.checked) {
      setDisabled(false);
      addRemoveClass(false);
    } else {
      setDisabled(true);
      addRemoveClass(true);
    }
  };

  const addRemoveClass = (status) => {
    if (status) {
      btnSubmitRef.current.classList.remove("active");
      btnSubmitRef.current.classList.add("inactive");
    } else {
      btnSubmitRef.current.classList.remove("inactive");
      btnSubmitRef.current.classList.add("active");
    }
  };

  const countryChange = (e) => {
    updateInputValue(e);
    if (e.target.value.toLowerCase() === "india" && product === PROD_LAUDITOR) {
      setQty(3);
      countrycode = "IN";
      // updateQty(3);
      // setMinvalue(3);
      setArrqty([3, 4, 5, 6, 7, 8, 9, 10]);
    } else {
      countrycode = "";
      // setMinvalue(1);
      setArrqty([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);
    }
  };

  const notify = (error, msg) => {
    if (error) toast.error(msg);
    else toast.success(msg);
  };

  const validateField = (fieldName, value) => {
    switch (fieldName) {
      case "email":
        break;

      default:
        break;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let isValid = isValidFields();
    if (isValid) {
      //this.setState({[name]: value}, () => { this.validateField(name,value)});
      const token = captchaRef.current.getValue();
      updateCaptchaToken(token);
      captchaRef.current.reset();
      saveProfUser(token);
    }
  };
  // console.log(testObj);
  const {
    fname,
    email,
    phone,
    country,
    cperson,
    countries,
    //qty,
    saveStarted,
    saveEnded,
    saved,
    message,
    customerRefId,
    updateInputValue,
    saveProfUser,
    redirectUrl,
    updateQty,
    updateEmail,
    updateCaptchaToken,
    updateCountry,
    updatePhone,
    updateContact,
    updateFirm,
    setQty,
    setProduct,
  } = useRegisterContext();

  let { qty, product } = useRegisterContext();
  qty = users > qty ? users : qty;
  qty = parseInt(qty);
  //console.log(qty);
  product = prod || product;
  if (process.env.REACT_APP_PRODUCT) {
    product = process.env.REACT_APP_PRODUCT;
  }

  console.log(product);
  setProduct(product);

  // const triggerInput = (enteredName, enteredValue) => {
  //   const input = document.getElementById("qty");
  //   console.log(input);
  //   const lastValue = input.value;
  //   input.value = enteredValue;
  //   const ev = document.createEvent("Event");
  //   ev.initEvent("change", true, false);
  //   input.dispatchEvent(ev);
  // };

  useEffect(() => {
    updateQty(qty);
  }, [qty, updateQty]);

  useEffect(() => {
    if (consumeremail !== "" && prod === PROD_VITAGIST) {
      setIsReadOnly(true);
      updateEmail(consumeremail);
      updateContact("#####");
      updateFirm("#####");
      updatePhone("0000000000");
      updateCountry("#####");
      setBtnTitle("Continue >");
    } else {
      setIsReadOnly(false);
      setBtnTitle("Register");
    }
  }, [consumeremail, updateEmail]);

  useEffect(() => {
    if (countrycode === undefined) {
      countrycode = "";
    }
    if (countrycode !== "") updateCountry(countrycode);
  }, [countrycode, updateCountry]);

  useEffect(() => {
    if (!saved && saveEnded && message !== "") {
      notify(!saved, message);
    }
  }, [saved, message, saveEnded]);

  useEffect(() => {
    if (redirectUrl !== "") {
      window.location.href = redirectUrl;
    }
  }, [redirectUrl]);

  //function to get logo based on product
  const getLogo = (product) => {
    switch (product) {
      case PROD_LAUDITOR:
        return lauditor_logo;
      case PROD_REGSWATCH:
        return regswatch_logo;
      case PROD_VITAGIST:
        return vitagist_logo;
      case PROD_CONTENT:
        return content_logo;
      case PROD_DGCOUNSEL:
        return dgcounsel_logo;
      default:
        return lauditor_logo;
    }
  };

  //Function used to limit the number of digits in Number field
  const limitInput = (e) => {
    e.target.value = e.target.value.trim();
    if (e.target.value.length >= 3) {
      e.target.value = e.target.value.slice(0, 2);
    }
  };

  const isValidForm = () => {
    let isValid = true;
    resetError();
    // console.log("on blur");
    if (isRequired("fname", prod)) {
      if (document.getElementById("fname").value.trim() === "" && isValid) {
        isValid = false;
        setFirmclass("fld-error");
        setFirmmsg("Firm Name is required.");
        // setFirmerror(true);
      } else if (
        document.getElementById("fname").value.trim().length < 3 &&
        isValid &&
        isRequired("fname", prod)
      ) {
        isValid = false;
        setFirmclass("fld-error");
        setFirmmsg("Firm Name should have minumum of 3 characters.");
        // setFirmerror(true);
      }
    }
    if (isRequired("email", prod)) {
      if (document.getElementById("email").value.trim() === "" && isValid) {
        isValid = false;
        setEmailclass("fld-error");
        setEmailmsg("Email is required.");
      } else if (
        !document
          .getElementById("email")
          .value.trim()
          .match(/^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/) &&
        isValid &&
        isRequired("email", prod)
      ) {
        isValid = false;
        setEmailclass("fld-error");
        setEmailmsg("Email is invalid.");
      }
    }

    if (isRequired("country", prod)) {
      if (document.getElementById("country").value.trim() === "" && isValid) {
        isValid = false;
        setCountryclass("fldselect-err");
        setCountrymsg("Country is required.");
      } else if (
        document.getElementById("cperson").value.trim() === "" &&
        isValid &&
        isRequired("cperson", prod)
      ) {
        isValid = false;
        setCpersonclass("fld-error");
        setCpersonmsg("Contact person is required.");
      }
    }

    if (isRequired("cperson", prod)) {
      if (
        document.getElementById("cperson").value.trim().length < 3 &&
        isValid
      ) {
        isValid = false;
        setCpersonclass("fld-error");
        setCpersonmsg("Contact person should have minumum of 3 characters.");
      } else if (
        document.getElementById("phone").value.trim() === "" &&
        isValid &&
        isRequired("phone", prod)
      ) {
        isValid = false;
        setPhoneclass("fld-error");
        setPhonemsg("Phone is required.");
      }
    }

    if (isRequired("phone", prod)) {
      if (
        !document
          .getElementById("phone")
          .value.trim()
          .match(/^[0-9]{10}$/) &&
        isValid
      ) {
        isValid = false;
        setPhoneclass("fld-error");
        setPhonemsg("Phone should be 10 digit number.");
      }
    }

    if (isRequired("qty", prod)) {
      if (parseInt(document.getElementById("qty").value) > 10) {
        isValid = false;
        setUsersclass("fld-error");
        setUsersmsg("Number of users should be less than or equal to 10");
      }
    } else {
      // console.log(document.getElementById("qty").value);
      resetError();
    }
    return isValid;
  };

  const isRequired = (fieldName, product) => {
    let required = true;
    switch (fieldName) {
      case "cperson":
        if (product === PROD_VITAGIST) required = false;
        break;
      case "fname":
        if (product === PROD_VITAGIST) required = false;
        break;
      case "country":
        if (product === PROD_VITAGIST) required = false;
        break;
      case "phone":
        if (product === PROD_VITAGIST) required = false;
        break;
      case "qty":
        if (product === PROD_VITAGIST || product === PROD_REGSWATCH)
          required = false;
        break;
      default:
        break;
    }
    return required;
  };

  const isValidFields = () => {
    let isValid = true;
    resetError();
    if (isRequired("fname", prod)) {
      if (document.getElementById("fname").value.trim() === "") {
        isValid = false;
        setFirmclass("fld-error");
        setFirmmsg("Firm Name is required.");
        // setFirmerror(true);
      }
      if (document.getElementById("fname").value.trim().length < 3) {
        isValid = false;
        setFirmclass("fld-error");
        setFirmmsg("Firm Name should have minumum of 3 characters.");
        // setFirmerror(true);
      }
    }
    if (isRequired("email", prod)) {
      if (document.getElementById("email").value.trim() === "") {
        isValid = false;
        setEmailclass("fld-error");
        setEmailmsg("Email is required.");
      }
      if (
        !document
          .getElementById("email")
          .value.trim()
          .match(/^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/)
      ) {
        isValid = false;
        setEmailclass("fld-error");
        setEmailmsg("Email is invalid.");
      }
    }
    if (isRequired("country", prod)) {
      if (document.getElementById("country").value.trim() === "") {
        isValid = false;
        setCountryclass("fldselect-err");
        setCountrymsg("Country is required.");
      }
    }
    if (isRequired("cperson", prod)) {
      if (
        document.getElementById("cperson").value.trim() === "" &&
        isRequired("cperson", prod)
      ) {
        isValid = false;
        setCpersonclass("fld-error");
        setCpersonmsg("Contact person is required.");
      }

      if (
        document.getElementById("cperson").value.trim().length < 3 &&
        isValid
      ) {
        isValid = false;
        setCpersonclass("fld-error");
        setCpersonmsg("Contact person should have minumum of 3 characters.");
      }
      if (document.getElementById("cperson").value.trim() === "" && isValid) {
        isValid = false;
        setCpersonclass("fld-error");
        setCpersonmsg("Contact person is required.");
      }
    }
    if (isRequired("phone", prod)) {
      if (document.getElementById("phone").value.trim() === "") {
        isValid = false;
        setPhoneclass("fld-error");
        setPhonemsg("Phone is required.");
      }
      if (
        !document
          .getElementById("phone")
          .value.trim()
          .match(/^[0-9]{10}$/)
      ) {
        isValid = false;
        setPhoneclass("fld-error");
        setPhonemsg("Phone should be 10 digit number.");
        // console.log("phone is invalid");
      }
    }
    return isValid;
  };

  const resetError = () => {
    setFirmclass("mod-input");
    setEmailclass("mod-input");
    setPhoneclass("mod-input");
    setCountryclass("mod-select");
    setCpersonclass("mod-input");
    setUsersclass("mod-input");
    setFirmmsg("");
    setPhonemsg("");
    setEmailmsg("");
    setCpersonmsg("");
    setCountrymsg("");
    setUsersmsg("");
  };

  const validateFields = (e) => {
    const isValid = isValidForm();
    if (isValid) {
      if (document.getElementById("terms").checked) {
        setDisabled(false);
        addRemoveClass(false);
      } else {
        setDisabled(true);
        addRemoveClass(true);
      }
    } else {
      setDisabled(true);
      addRemoveClass(true);
    }
  };

  return (
    <>
      <Header />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        bodyClassName="toastBody"
      />

      <div className="section section-center">
        {/* <div className="title">Sign up</div> */}

        <form
          className="mod-form"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <img src={getLogo(prod)} alt="company logo" className="logo" />
          <div className="form-group">
            <p className="sub-title">Complete the form to subscribe.</p>
          </div>
          {isRequired("fname", prod) && (
            <div className="form-group">
              <label className="mod-label" htmlFor="fname">
                Firm Name
              </label>
              <input
                className={firmclass}
                maxLength={50}
                type="text"
                id="fname"
                name="fname"
                value={fname}
                onChange={updateInputValue}
                onBlur={validateFields}
                autoFocus
                required
              />
              <div className="error-msg">{firmmsg}</div>
            </div>
          )}

          <div className="form-group">
            <label className="mod-label" htmlFor="email">
              Email
            </label>
            <input
              className={emailclass}
              maxLength={255}
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={updateInputValue}
              onBlur={validateFields}
              required
              readOnly={isReadOnly}
            />
            <div className="error-msg">{emailmsg}</div>
            <input type="hidden" id="product" name="product" value={product} />
          </div>
          {isRequired("country", prod) && (
            <div className="form-group">
              <label className="mod-label" htmlFor="country">
                Country
              </label>
              <select
                className={countryclass}
                id="country"
                name="country"
                value={country}
                onChange={countryChange}
                onBlur={validateFields}
                required
              >
                {countries.map((country) => {
                  return (
                    <option key={country[1]} value={country[1]}>
                      {country[0]}
                    </option>
                  );
                })}
              </select>
              <div className="error-msg">{countrymsg}</div>
            </div>
          )}

          {isRequired("cperson", prod) && (
            <div className="form-group">
              <label className="mod-label" htmlFor="cperson">
                Contact Person
              </label>
              <input
                className={cpersonclass}
                maxLength={50}
                type="text"
                id="cperson"
                name="cperson"
                value={cperson}
                onChange={updateInputValue}
                onBlur={validateFields}
                required
              />
              <div className="error-msg">{cpersonmsg}</div>
            </div>
          )}

          {isRequired("phone", prod) && (
            <div className="form-group">
              <label className="mod-label" htmlFor="phone">
                Phone
              </label>
              <input
                className={phoneclass}
                maxLength={10}
                type="text"
                id="phone"
                name="phone"
                value={phone}
                onChange={updateInputValue}
                onBlur={validateFields}
                required
              />
              <div className="error-msg">{phonemsg}</div>
            </div>
          )}

          {isRequired("qty", prod) && (
            <div className="form-group">
              <label className="mod-label" htmlFor="users">
                Number of Users
              </label>
              {/* <input
              className={usersclass}
              type="number"
              maxLength={2}
              id="qty"
              name="qty"
              value={qty}
              min={minvalue}
              max={10}
              onChange={updateInputValue}
              onInput={limitInput}
              onBlur={validateFields}
              required
            /> */}
              <select
                className={countryclass}
                id="qty"
                name="qty"
                value={qty}
                onChange={updateInputValue}
                onBlur={validateFields}
                required
              >
                {arrqty.map((seq) => {
                  return (
                    <option key={seq} value={seq}>
                      {seq}
                    </option>
                  );
                })}
              </select>
              <div className="error-msg">{usersmsg}</div>
            </div>
          )}
          <div className="form-group">
            <label className="mod-label" htmlFor="terms">
              <input
                className="mod-checkbox"
                id="terms"
                name="terms"
                type="checkbox"
                onClick={agreeClick}
              />
              I accept the Terms of Service and Privacy Policy.
            </label>
          </div>
          <div className="form-group">
            <p className="help-text">
              By signing up you agreeing to our
              <a href="https://digicoffer.com/digicoffer_terms.pdf">Terms</a> &
              &nbsp;
              <a href="https://digicoffer.com/privacy.html">Privacy Policy</a>
            </p>
          </div>
          <div className="form-group">
            <div className="cap-center">
              <Recaptcha
                sitekey={process.env.REACT_APP_SITE_KEY}
                ref={captchaRef}
              />
            </div>
          </div>
          <div className="button-container">
            <button
              id="btnSubmit"
              name="btnSubmit"
              ref={btnSubmitRef}
              disabled={disabled}
              className="btn-small hot inactive"
              onClick={handleSubmit}
            >
              {btnTitle}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default Register;
