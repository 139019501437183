import {
  PROD_LAUDITOR,
  PROD_REGSWATCH,
  PROD_COFFER,
  PROD_CONTENT,
  PROD_DGCOUNSEL,
  PROD_VITAGIST,
} from "../actions";

export const getRediretUrl = (product) => {
  switch (product) {
    case PROD_REGSWATCH:
      return process.env.REACT_APP_REGSWATCH_URL;
    case PROD_LAUDITOR:
      return process.env.REACT_APP_LAUDITOR_URL;
    case PROD_VITAGIST:
      return process.env.REACT_APP_VITAGIST_URL;
    default:
      return process.env.REACT_APP_LAUDITOR_URL;
  }
};
