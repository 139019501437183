import {
  GET_COUNTRIES_SUCCESS,
  GET_COUNTRIES_ERROR,
  UPDATE_REG_VALUE,
  SAVE_PROFUSER_BEGIN,
  SAVE_PROFUSER_SUCCESS,
  SAVE_PROFUSER_ERROR,
} from "../actions";

const register_reducer = (state, action) => {
  if (action.type === GET_COUNTRIES_SUCCESS) {
    return { ...state, countries: action.payload };
  } else if (action.type === GET_COUNTRIES_ERROR) {
    //need to handle this more better for the time just returning state
    return { ...state };
  } else if (action.type === UPDATE_REG_VALUE) {
    const { name, value } = action.payload;
    return { ...state, [name]: value };
  } else if (action.type === SAVE_PROFUSER_BEGIN) {
    return {
      ...state,
      saveStarted: action.payload.saveStarted,
      saveEnded: false,
    };
  } else if (action.type === SAVE_PROFUSER_SUCCESS) {
    return {
      ...state,
      saveStarted: false,
      saveEnded: true,
      saved: true,
      customerRefId: action.payload.customerRefId,
      message: action.payload.message,
      redirectUrl: action.payload.redirectUrl,
    };
  } else if (action.type === SAVE_PROFUSER_ERROR) {
    // console.log(action.payload.message);
    return {
      ...state,
      saveStarted: false,
      saveEnded: true,
      saved: false,
      message: action.payload.message,
    };
  }
  throw new Error(`No Matching "${action.type}" - action type`);
};

export default register_reducer;
