import React from "react";
import Register from "./pages/Register";
import Success from "./pages/Success";
import Cancelled from "./pages/Cancelled";
import Renew from "./pages/Renew";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

const App = () => {
  return (
    <Router forceRefresh={true}>
      <Routes>
        <Route path="/" element={<Register />} />
        <Route path="/renew" element={<Renew />} />
        <Route path="/success/:sessionid" element={<Success />} />
        <Route path="/cancelled" element={<Cancelled />} />
      </Routes>
    </Router>
  );
};

export default App;
