import React from "react";
import ReactDOM from "react-dom/client";
import App from "./app";
import { RegisterProvider } from "./context/register_context";
import { SuccessProvider } from "./context/success_context";
import { RenewProvider } from "./context/renew_context";
import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <RegisterProvider>
    <RenewProvider>
      <SuccessProvider>
        <App />
      </SuccessProvider>
    </RenewProvider>
  </RegisterProvider>
);
