import React, { useRef } from "react";
import lauditor_logo from "../resources/images/lauditor.png";
import regswatch_logo from "../resources/images/regswatch.png";
import vitagist_logo from "../resources/images/vitagist.png";
import content_logo from "../resources/images/content.png";
import dgcounsel_logo from "../resources/images/dgcounsel.png";

import {
  PROD_LAUDITOR,
  PROD_REGSWATCH,
  PROD_COFFER,
  PROD_CONTENT,
  PROD_DGCOUNSEL,
  PROD_VITAGIST,
} from "../actions";

const StatusRegion = ({
  transComplete,
  transSuccess,
  accountCreated,
  accountUpdated,
  product,
}) => {
  //const step2 = useRef();

  let step2Classes = "step";
  let step2StatusClasses = "step-number";
  let step2Completed = false;
  let step3Completed = false;
  const tick = "\u2713";

  let step3Classes = "step";
  let step3StatusClasses = "step-number";
  let title = accountUpdated ? "Renew subscription" : "Account activation";

  if ((accountCreated || accountUpdated) && transSuccess) {
    step2Completed = true;
    step3Completed = true;
    step2Classes = "step completed";
    step2StatusClasses = "step-number step-success";

    step3Classes = "step completed";
    step3StatusClasses = "step-number step-success";
  } else if (!accountCreated && !accountUpdated && transSuccess) {
    step2Classes = "step completed";
    step2StatusClasses = "step-number step-failure";

    step3Classes = "step completed";
    step3StatusClasses = "step-number step-failure";
  }

  //function to get logo based on product
  const getLogo = (product) => {
    switch (product) {
      case PROD_LAUDITOR:
        return lauditor_logo;
      case PROD_REGSWATCH:
        return regswatch_logo;
      case PROD_VITAGIST:
        return vitagist_logo;
      case PROD_CONTENT:
        return content_logo;
      case PROD_DGCOUNSEL:
        return dgcounsel_logo;
      default:
        return lauditor_logo;
    }
  };
  return (
    <div className="section section-center">
      <div className="success">
        <div className="section-left">
          <img src={getLogo(product)} alt="company logo" className="logo" />
        </div>
        <div className="progress-wrapper">
          <div className="step completed">
            <span className="step-number step-success">&#10003;</span>
            <span className="step-label">Payment</span>
          </div>
          <div className="connector"></div>
          <div className={step2Classes}>
            <span className={step2StatusClasses}>
              {step2Completed && <div>{tick}</div>}
            </span>
            <span className="step-label">{title}</span>
          </div>
          <div className="connector"></div>
          <div className={step3Classes}>
            <span className={step3StatusClasses}>
              {step3Completed && <div>{tick}</div>}
            </span>
            <span className="step-label">Completed</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatusRegion;
