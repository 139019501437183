import axios from "axios";
import React, { useEffect, useContext, useReducer, useState } from "react";
import reducer from "../reducers/register_reducer";

import {
  GET_COUNTRIES_SUCCESS,
  GET_COUNTRIES_ERROR,
  UPDATE_REG_VALUE,
  SAVE_PROFUSER_BEGIN,
  SAVE_PROFUSER_SUCCESS,
  SAVE_PROFUSER_ERROR,
  SAVED,
  FAILED,
  PROD_LAUDITOR,
  PROD_REGSWATCH,
  PROD_VITAGIST,
} from "../actions";

const initialState = {
  fname: "",
  email: "",
  phone: "",
  country: "",
  cperson: "",
  countries: [],
  qty: 1,
  product: PROD_LAUDITOR,
  captchatoken: "",
  saveStarted: false,
  saveEnded: true,
  savedStatus: "",
  message: "",
  customerRefId: "",
  redirectUrl: "",
  profUid: "",
  profId: "",
};

const RegisterContext = React.createContext();

export const RegisterProvider = ({ children }) => {
  const restUrl = process.env.REACT_APP_REST_ENDPOINT;
  const authPayUrl = process.env.REACT_APP_AUTH_DOIMAN;
  const productId = process.env.REACT_APP_STRIPE_BASIC_PRODUCT_ID;
  const bearer_token = process.env.REACT_APP_BEARER_TOKEN;
  const [state, dispatch] = useReducer(reducer, initialState);
  //console.log(bearer_token);
  useEffect(() => {
    fetchCountries(restUrl);
  }, [restUrl]);

  //function to update the initial Number of users value passed as license
  const updateEmail = (email) => {
    initialState.email = email;
  };

  const updateFirm = (fname) => {
    initialState.fname = fname;
  };

  const updateContact = (cperson) => {
    initialState.cperson = cperson;
  };

  const updatePhone = (phone) => {
    initialState.phone = phone;
  };

  const updateQty = (qty) => {
    initialState.qty = qty;
  };

  const setQty = (qty) => {
    state.qty = qty;
  };

  const setProduct = (product) => {
    state.product = product;
  };

  const updateCountry = (country) => {
    //The code below is applies for Vitagist only because, registration already happened for vitagist
    if (country === "#####") {
      initialState.country = country;
    } else initialState.country = country === "IN" ? "India" : "";
  };

  const updateCaptchaToken = (token) => {
    initialState.captchatoken = token;
  };

  const updateInputValue = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    dispatch({ type: UPDATE_REG_VALUE, payload: { name, value } });
  };

  const getProductId = (country, sub_prod) => {
    console.log(sub_prod);
    console.log(country);
    if (sub_prod === PROD_VITAGIST) {
      return process.env.REACT_APP_STRIPE_VGIST_BASIC_PRODUCT_ID;
    } else {
      return sub_prod === PROD_REGSWATCH
        ? process.env.REACT_APP_STRIPE_RWATCH_BASIC_PRODUCT_ID
        : country === "India"
        ? process.env.REACT_APP_STRIPE_INDIA_PRODUCT_ID
        : process.env.REACT_APP_STRIPE_BASIC_PRODUCT_ID ||
          "price_1NIPxgJYeHtZmuJDn7zsraEY";
    }
  };

  const stripePay = async (custId, payType, country = "", profUid, profId) => {
    const sub_prod = process.env.REACT_APP_PRODUCT || state.product;
    const productId = getProductId(country, sub_prod);
    console.log(state.product);
    console.log(productId);
    const stripeUrl = `${authPayUrl}/stripe/create-checkout-session`;
    try {
      const jsonData = {
        priceId: productId,
        qty: state.qty || 1,
        customerRefId: custId,
        email: state.email,
        firmName: state.fname,
        country: state.country,
        contactPerson: state.cperson,
        product: state.product,
        contactPhone: state.phone,
        token: state.captchatoken,
        paymentType: payType,
        profUid: profUid,
        profId: profId,
      };
      const response = await axios.post(stripeUrl, jsonData, {
        validateStatus: function (status) {
          return status >= 200 && status <= 500;
        },
      });
      // console.log(`response ===  ${response["data"].error.message}`);
      // for (var key in response) {
      //   console.log(key);
      //   console.log(response[key]);
      // }
      if (response.status === 200)
        return { data: response.data, status: response.status };
      else {
        return {
          data: null,
          status: response.status,
          error: { message: response["data"].error.message },
        };
      }
      //const profUserData = await response.data;
      //console.log(response);
    } catch (error) {
      // console.log(error);
      return { data: null, status: 400, error: { message: error } };
    }
  };

  // const checkDuplicate = async () => {
  //   const checkUserUrl = `${authPayUrl}/auth/userexists`;
  //   dispatch({
  //     type: SAVE_PROFUSER_BEGIN,
  //     payload: { saveStarted: true, savedStatus: "", saveEnded: false },
  //   });
  //   const jsonData = {
  //     firmName: state.fname,
  //     email: state.email,
  //   };
  //   const response = await axios.post(checkUserUrl, jsonData);
  //   const profUserData = await response.data;
  //   console.log(response);
  // };

  const saveProfUser = async (token) => {
    let param = "";
    if (state.customerRefId !== "" && state.customerRefId !== undefined) {
      param = `/${state.customerRefId}`;
    }
    const saveUserUrl = `${authPayUrl}/auth/register${param}`;
    dispatch({
      type: SAVE_PROFUSER_BEGIN,
      payload: { saveStarted: true, savedStatus: "", saveEnded: false },
    });
    const qty = state.qty === undefined || state.qty === "" ? 1 : state.qty;
    try {
      // const config = {
      //   headers: {
      //     Authorization: `Bearer ${bearer_token}`,
      //   },
      // };

      const jsonData = {
        firmName: state.fname,
        email: state.email,
        country: state.country,
        contactPerson: state.cperson,
        product: state.product,
        phoneNumber: state.phone,
        users: qty,
        token: token,
      };
      let response = "";
      // console.log(state.customerRefId);
      if (state.customerRefId === "" || state.customerRefId === undefined)
        response = await axios.post(saveUserUrl, jsonData);
      else response = await axios.patch(saveUserUrl, jsonData);
      let profUserData = await response.data;
      // console.log(response);
      // dispatch({
      //   type: SAVE_PROFUSER_SUCCESS,
      //   payload: {
      //     customerRefId: profUserData.id,
      //     user: profUserData,
      //     savedStatus: SAVED,
      //     message: "Saved Successfully",
      //   },
      // });
      //console.log(profUserData.user.id);
      const stresponse = await stripePay(
        profUserData.user.id,
        "SUBSCRIBE",
        state.country,
        profUserData.user.profUid,
        profUserData.user.profId
      );
      // console.log(`stresponse == ${stresponse.status}`);
      if (stresponse.status === 200) {
        // console.log(stresponse.data);

        dispatch({
          type: SAVE_PROFUSER_SUCCESS,
          payload: {
            customerRefId: profUserData.id,
            user: profUserData,
            savedStatus: SAVED,
            message: "Saved Successfully",
            redirectUrl: stresponse.data.url,
          },
        });
      } else {
        const errorMsg = stresponse.error.message;
        // console.log(errorMsg);
        dispatch({
          type: SAVE_PROFUSER_ERROR,
          payload: { savedStatus: FAILED, message: errorMsg },
        });
      }
    } catch (error) {
      // console.log(error);
      const errorMessage = error.response.data.msg || error.message;
      dispatch({
        type: SAVE_PROFUSER_ERROR,
        payload: { savedStatus: FAILED, message: errorMessage },
      });
    }
  };

  const fetchCountries = async (url) => {
    const countryUrl = `${url}/countries`;
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${bearer_token}`,
        },
      };
      const response = await axios.get(countryUrl, config);
      let data = await response.data.data.countries;
      if (data.length >= 1) {
        // console.log(data[0][0]);
        // console.log(data[0][1]);
        if (data[0][0] === "Select") {
          data[0][1] = "";
        }
      }
      dispatch({ type: GET_COUNTRIES_SUCCESS, payload: data });
    } catch (error) {
      // console.log(error);
      dispatch({ type: GET_COUNTRIES_ERROR });
    }
  };
  return (
    <RegisterContext.Provider
      value={{
        ...state,
        updateInputValue,
        saveProfUser,
        stripePay,
        updateQty,
        updateCaptchaToken,
        updateCountry,
        updateEmail,
        updatePhone,
        updateContact,
        updateFirm,
        setQty,
        setProduct,
      }}
    >
      {children}
    </RegisterContext.Provider>
  );
};

export const useRegisterContext = () => {
  return useContext(RegisterContext);
};
