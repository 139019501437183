import { GET_STRIPE_SESSION_STATUS } from "../actions";

const success_reducer = (state, action) => {
  if (action.type === GET_STRIPE_SESSION_STATUS) {
    console.log(action.payload);
    const payload = action.payload;
    return {
      ...state,
      session: payload.data,
      transComplete: payload.transComplete,
      transSuccess: payload.transSuccess,
      accountCreated: payload.accountCreated,
      accountUpdated: payload.accountUpdated,
    };
  }
  throw new Error(`No Matching "${action.type}" - action type`);
};

export default success_reducer;
