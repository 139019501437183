import {
  GET_PROFUSER_BEGIN,
  GET_PROFUSER_SUCCESS,
  GET_PROFUSER_ERROR,
  UPDATE_REG_VALUE,
  MATCHED,
} from "../actions";

const renew_reducer = (state, action) => {
  if (action.type === GET_PROFUSER_BEGIN) {
    return { ...state, searchStarted: true, searchEnded: false };
  } else if (action.type === GET_PROFUSER_SUCCESS) {
    return {
      ...state,
      searchStarted: false,
      searchEnded: true,
      searchStatus: action.payload.searchStatus,
      customerRefId: action.payload.customerRefId,
      message: action.payload.message,
      redirectUrl: action.payload.redirectUrl,
    };
  } else if (action.type === GET_PROFUSER_ERROR) {
    return {
      ...state,
      searchStatus: action.payload.searchStatus,
      message: action.payload.message,
    };
  } else if (action.type === UPDATE_REG_VALUE) {
    const { name, value } = action.payload;
    return { ...state, [name]: value };
  }
  throw new Error(`No Matching "${action.type}" - action type`);
};

export default renew_reducer;
