import React from "react";
import { getRediretUrl } from "../utils/helpers";

function RenewRegion({ product }) {
  let prod = product;
  if (process.env.REACT_APP_PRODUCT) {
    prod = process.env.REACT_APP_PRODUCT;
  }
  const redirect = (e) => {
    e.preventDefault();
    window.location.href = getRediretUrl(prod);
  };
  return (
    <div className="section section-center">
      <div className="success">
        <div className="spacer">&nbsp;</div>
        <div className="success-title">Subscription renewed Successfully</div>
        <div className="success-msg">
          Thank you for your purchase. Your payment has been successfully
          processed.
        </div>
        <div className="success-msg">
          If you have any questions or need further assistance, please don't
          hesitate to contact our customer support.
        </div>
        <button
          id="btnSubmit"
          name="btnSubmit"
          className="btn-small hot active"
          onClick={redirect}
        >
          Sign in
        </button>
      </div>
    </div>
  );
}

export default RenewRegion;
